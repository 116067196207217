import type { FC, MouseEventHandler, ReactNode, RefObject } from 'react';

import { StyledTextButton } from 'styles/buttons';

import Loader from '../Loader';

export interface TextButtonProps {
  view?: 'primary' | 'secondary' | 'table';
  type?: 'button' | 'submit' | 'reset';
  dataTestId: string;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  width?: string;
  isRelative?: boolean;
  dataTip?: string;
  dataFor?: string;
  reference?: RefObject<HTMLButtonElement>;
  darkTextColor?: boolean;
}

const TextButton: FC<TextButtonProps> = ({
  view = 'primary',
  type = 'button',
  dataTestId,
  disabled,
  isLoading,
  icon,
  children,
  onClick,
  width,
  isRelative = false,
  dataTip,
  dataFor,
  reference,
  darkTextColor,
}) => {
  return (
    <StyledTextButton
      ref={reference}
      className={`${view}`}
      type={type}
      isRelative={isRelative}
      disabled={disabled || isLoading}
      onClick={onClick}
      data-testid={dataTestId}
      width={width}
      data-tip={dataTip}
      data-for={dataFor}
      aria-describedby={dataFor}
      darkTextColor={darkTextColor}
    >
      {isLoading ? <Loader size={12} /> : icon}
      {children}
    </StyledTextButton>
  );
};

export default TextButton;
